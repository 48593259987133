import React from "react";
import "./CategoryProduct.css";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import ProductItem from "../ProductItem/ProductItem";
const ProductSection = ({ category, products, isLoading }) => {
  return (
    <div className="product-section">
      <h2>{category.name}</h2>
      {isLoading ? (
        <p>Loading products...</p>
      ) : (
        <div className="product-grid  row row-cols-xxl-5">
          {products.map((product) => (
            <ProductItem {...product} key={product.id} />
          ))}
        </div>
      )}
    </div>
  );
};

export default ProductSection;
