import React from 'react';
import { Link } from 'react-router-dom';
import './HomeCategory.scss';

const HomeCategory = ({ category: { id, name, description, imageUrl } }) => {
	return (
		<div className='col-6 mb-3 home-category'>
			<Link to={`/products/?category=${id}`} style={{ backgroundImage: `url(${imageUrl})`, backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: "center" }}>
				<h2 className='h4'>
					<strong>{name}</strong>
					<button type="button" className="viewDetailsBtn">
						<svg width="6" height="10" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1 13L7 7L1 1" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
						</svg>
					</button>
				</h2>
				{/* <p className='m-0'>{description}</p> */}
			</Link>
		</div>
	);
};

export default HomeCategory;
