import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import sideBannerImg from "../../assets/images/signin/login-banner.jpeg";
//style
import "./Login.css";

//components
import Error from "../../components/Error/Error";
import CustomButton from "../../components/CustomButton/CustomButton";
import CustomInput from "../../components/CustomInput/CustomInput";

//assets
import Logo from "../../assets/images/logo/logo.png";
// actions
import { loginUser, updateError } from "../../store/actions/user";
// selectors
import {
  selectUserData,
  selectUserLoading,
  selectUserError,
} from "../../store/selectors/user";
// helpers
import { validateLoginData } from "../../utils/validators";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const user = useSelector(selectUserData);
  const userLoading = useSelector(selectUserLoading);
  const userError = useSelector(selectUserError);

  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    if (user) {
      history.push("/");
    }
    dispatch(updateError({}));

  }, [user, history]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "email") setEmail(value);
    if (name === "password") setPassword(value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const { valid, errors } = validateLoginData({ email, password });

    if (!valid) {
      dispatch(updateError(errors));
    } else {
      dispatch(loginUser(email, password));
    }
  };

  return (
    <div className="login-page">
      <div className="leftBox">
        <div className="leftBanner b-primary ">
          <h2>One Step Closer to What Matters</h2>
          <p>
            Log in to access a personalized experience designed to help you
            reach your goals. Your journey starts here.
          </p>
          <img className="mx-auto" src={sideBannerImg} alt="banner" />
        </div>
      </div>
      <div className="rightBox">
        <Link to="/">
          <img src={Logo} alt="logo" />
        </Link>

        <h1 className="h2 mb-3 ">Login</h1>

        <div className="columns is-centered is-vcentered">
          <div className="column is-one-quarter">
            <form onSubmit={handleSubmit}>
              <CustomInput
                className="form-control form-control-lg mb-3"
                name="email"
                placeholder="Email"
                aria-describedby="emailHelp"
                value={email}
                onChange={handleChange}
                error={userError?.email}
              />
              <CustomInput
                type="password"
                className="form-control form-control-lg mb-3"
                name="password"
                placeholder="Password"
                value={password}
                onChange={handleChange}
                error={userError?.password}
              />
              {userError?.error && <Error error={userError.error} />}
              <CustomButton
                isLoading={userLoading}
                type="submit"
                className="button-primary mb-3 w-100 "
              >
                Log In
              </CustomButton>
            </form>
            <p className="text-center smallText">
              Don't have an account?&nbsp;
              <Link to="/signup" className="my-3">
                <b>Sign Up</b>
              </Link>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
