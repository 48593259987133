import React from 'react';

class DropdownSort extends React.Component {
	constructor() {
		super();
		this.state = {
			show: false,
			selectedSort: 'default',
		};

		this.setWrapperRef = this.setWrapperRef.bind(this);
		this.handleClickOutside = this.handleClickOutside.bind(this);
	}

	componentDidMount() {
		document.addEventListener('mousedown', this.handleClickOutside);
	}

	componentWillUnmount() {
		document.removeEventListener('mousedown', this.handleClickOutside);
	}
	setWrapperRef(node) {
		this.wrapperRef = node;
	}
	handleClickOutside(event) {
		if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
			this.hideDropdown();
		}
	}
	hideDropdown() {
		this.setState({
			show: false
		});
	}

	toggleDropdown() {
		this.setState(prevState => ({
			show: !prevState.show,
		}));
	}

	handleSortClick(sort) {
		const { params, history } = this.props;
		const path = history.location.pathname;
		params.get('sort') ? params.set('sort', sort) : params.append('sort', sort);
		this.setState({ selectedSort: sort });
		this.hideDropdown();
		history.push(`${path}?${params.toString()}`);
	}
	render() {
		return (
			<div className='dropdown' ref={this.setWrapperRef}>
				<button className=' d-flex align-items-center rounded-0  button-primary' onClick={() => this.toggleDropdown()}>
					Sort by :&nbsp; <b>{this.state.selectedSort.charAt(0).toUpperCase() + this.state.selectedSort.slice(1)}</b> <i className={`fa-light ms-auto ${this.state.show ? 'fa-chevron-up' : 'fa-chevron-down'}`}></i>
				</button>
				<div className={`dropdown-menu rounded-0 m-0 w-100 ${this.state.show ? 'show' : ''}`} aria-labelledby='dropdownMenuButton'>
					<button
						className='dropdown-item'
						onClick={() => {
							this.handleSortClick('default');
						}}>
						Default
					</button>
					<button
						className='dropdown-item'
						onClick={() => {
							this.handleSortClick('ascending');
						}}>
						Ascending
					</button>
					<button
						className='dropdown-item'
						onClick={() => {
							this.handleSortClick('descending');
						}}>
						Descending
					</button>
				</div>
			</div>
		);
	}
}

export default DropdownSort;
