import React from "react";

const STYLE = {
  border: "1px solid #ebebd3",
  minHeight: "80px",
};
function CustomTextarea({ label, value, onChange, ...otherProps }) {
  return (
    <div className="form-group">
      <label className="label pt-2">{label} </label>
      <textarea style={STYLE} value={value} onChange={onChange} {...otherProps}>
        {value}
      </textarea>
    </div>
  );
}

export default CustomTextarea;
