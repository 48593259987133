import axios from "axios";
const instance = axios.create({
  // baseURL: "http://127.0.0.1:5001/dgamerz/us-central1/api",
  baseURL: "https://us-central1-dgamerz.cloudfunctions.net/api",
});

// Set the default Authorization header
const authToken = localStorage.getItem("Authorization");
instance.defaults.headers.common["Authorization"] = `${authToken}`;
export default instance;
