const CartActionTypes = {
	ADD_TO_CART: 'ADD_TO_CART',
	REMOVE_FROM_CART: 'REMOVE_FROM_CART',
	ADD_ORDER_DETAILS: 'ADD_O,RDER_DETAILS',
	CLEAR_CART: 'CLEAR_CART',
	 INCREMENT_QUANTITY: 'INCREMENT_QUANTITY',
    DECREMENT_QUANTITY: 'DECREMENT_QUANTITY'
};

export default CartActionTypes;
