import React from "react";
import "./Slider.css";
import image1 from "../../assets/images/banners/1.png";
// import image2 from "../../assets/images/banners/2.png";
import image3 from "../../assets/images/banners/3.png";
import image4 from "../../assets/images/banners/4.png";
import Slide from "./Slide/Slide";
import Timer from "../../utils/Timer";

class Slider extends React.Component {
  constructor() {
    super();
    this.state = {
      banners: [
        {
          image: image1,
          link: "/products",
          tagLine: `Womens' Appreals`,
        },
        // {
        //   image: image2,
        //   link: "/products",
        //   tagLine: "Mens' Appreals",
        // },
        {
          image: image3,
          link: "/products",
          tagLine: "Mens' Appreals",
        },
        {
          image: image4,
          link: "/products",
          tagLine: "Mens' Appreals",
        },
      ],
      displayBanner: "",
      activeBannerIndex: 0,
      show: true,
    };

    this.i = 0;
    this.timer = new Timer(() => {
      this.i += 1;
      if (this.i > this.state.banners.length - 1) this.i = 0;
      this.setState({
        displayBanner: this.state.banners[this.i],
        activeBannerIndex: this.i,
      });
    }, 5000);
  }

  componentDidMount() {
    this.setState({ displayBanner: this.state.banners[0] });
    this.timer.start();
  }

  componentWillUnmount() {
    this.timer.stop();
  }

  handleClick(e, number) {
    e.preventDefault();
    let slideNumber =
      number <= this.state.banners.length - 1 && number >= 0 ? number : 0;
    this.setState({
      displayBanner: this.state.banners[slideNumber],
      activeBannerIndex: slideNumber,
      show: true,
    });
    this.timer.reset();
  }

  render() {
    const {
      displayBanner: { image, link, tagLine },
      activeBannerIndex,
      banners,
      show,
    } = this.state;
    return (
      <div className="slider mb-3" >
        <Slide
          image={image}
          link={link}
          tagLine={tagLine}
          active={activeBannerIndex}
          total={banners.length}
          show={show}
          clickFunction={(e, number) => this.handleClick(e, number)}
        />
      </div>
    );
  }
}

export default Slider;
