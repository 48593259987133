import React from "react";
import HomeCategory from "../../components/HomeCategory/HomeCategory";
import ShowAllProductsButton from "../ShowAllProductsButton/ShowAllProductsButton";

function CategoriesList({ categories }) {
  return (
    <>
      <div className="d-flex justify-content-between">
        <h2>Categories</h2>
        <ShowAllProductsButton />
      </div>
      <div className="row row-cols-xl-4 row-cols-lg-4 row-cols-md-3 row-cols-sm-2 mt-3">
        {categories.map((category) => {
          return <HomeCategory category={category} key={category.id} />;
        })}
      </div>
    </>
  );
}

export default CategoriesList;
