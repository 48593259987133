import React, { useEffect } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';

// components
import Layout from '../../components/Layout/Layout';
import CartIsEmpty from '../../components/CartIsEmpty/CartIsEmpty';
import CartList from '../../components/CartList/CartList';

//actions
import { decrementQuantity, incrementQuantity, removeFromCart } from '../../store/actions/cart';

//selectors
import { selectCartProducts, selectCartTotal } from '../../store/selectors/cart';

//styles
import './Cart.css';
import { Link, useHistory } from "react-router-dom";
import { selectUserData, selectUserError, selectUserLoading } from '../../store/selectors/user';
import { updateError } from '../../store/actions/user';

function Cart({ products, removeFromCart,decrementQuantity,incrementQuantity, history, total }) {
	
	const dispatch = useDispatch();
	 
	
	// User selectors
	const user = useSelector(selectUserData);
	const userLoading = useSelector(selectUserLoading);
	const userError = useSelector(selectUserError);
  
	useEffect(() => {
	  if (!user && !userLoading) {
		// Redirect to sign-in if not logged in
		history.push("/login");
	  }
	  dispatch(updateError({})); // Reset any existing user errors
	}, [user, userLoading, history, dispatch]);
  
	return (
		<Layout title='Cart'>
			<div className='cart-page container-fluid container-min-max-width d-flex flex-column mt-3'>
				{products.length ? (
					<CartList goBack={history.goBack} products={products}
					decrementQuantity={decrementQuantity} incrementQuantity={incrementQuantity}
					removeFromCart={removeFromCart} total={total} />
				) : (
					<CartIsEmpty />
				)}
			</div>
		</Layout>
	);
}

const mapStateToProps = createStructuredSelector({
	products: selectCartProducts,
	total: selectCartTotal
});

function mapDispatchToProps(dispatch) {
	return {
		removeFromCart: (payload) => dispatch(removeFromCart(payload)),
		decrementQuantity: (payload) => dispatch(decrementQuantity(payload)),
		incrementQuantity: (payload) => dispatch(incrementQuantity(payload))
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(Cart);
