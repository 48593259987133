import React from 'react';

function BackButton({ goBack }) {
	return (
		<button className='button-primary' onClick={() => goBack()}>
			Back
		</button>
	);
}

export default BackButton;
