import React from "react";
import whyNmawImage from "../../assets/images/banners/why_nmaw.jpg";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

//actions
import { getAllCategoriesIfNecessary } from "../../store/actions/categories";
import {
  selectCategoriesData,
  selectCategoriesLoading,
} from "../../store/selectors/categories";

// components
import Layout from "../../components/Layout/Layout";
import Slider from "../../components/Slider/Slider";
import CategoriesList from "../../components/CategoriesList/CategoriesList";
import ShowAllProductsButton from "../../components/ShowAllProductsButton/ShowAllProductsButton";
import WithSpinner from "../../components/HOCs/WithSpinner";
import ProductSection from "../../components/CategoryProduct/CategoryProduct";
import { getAllProductsIfNecessary } from "../../store/actions/products";
import {
  selectProductsData,
  selectProductsLoading,
} from "../../store/selectors/products";

const AboutNmaw = () => {
  return (
    <>
      <div className="my-5">
        <div className="row">
          <div className="col-md-6 d-flex flex-column justify-content-center ">
            <h1>Why NMAW </h1>
            <p>
              The Medallion is Empowered by unity! It's not just a symbol, it's
              our identity! What better way to express? It's never about
              individual achievement, but collective success! We strive daily
              for the people we love! Our well-being, education, daily efforts,
              and spiritual connection all serve one purpose: Family. Never Me,
              Always We! #NMAW the movement is moving! #nevermealways #progress
            </p>
            <span>#nevermealways #progress</span>
          </div>
          <div className="col-md-6 d-flex justify-content-center justify-content-md-end mt-5 mt-md-0">
            <div>
              <img
                className=""
                src={whyNmawImage}
                alt="Why NMAW"
                style={{
                  height: "424px",
                  maxWidth: "100%",
                  boxShadow: "#6a6a6a3b -26px -26px 0px",
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
const CategoriesSectionWithSpinner = WithSpinner(({ categories }) => (
  <>
    <div className="container-fluid px-0">
      <Slider />
    </div>
    <div className="container container-min-max-width">
      <AboutNmaw />
      <CategoriesList categories={categories} />
    </div>
  </>
));

class Home extends React.Component {
  componentDidMount() {
    this.props.getAllCategoriesIfNecessary();
    this.props.getAllProductsIfNecessary();
  }

  render() {
    const { categoriesLoading, categories, productsLoading, products } =
      this.props;
    return (
      <Layout title="Home">
        <CategoriesSectionWithSpinner
          isLoading={categoriesLoading}
          categories={categories}
        />

        {/* Render a section for each category */}
        <div className="container">
          {categories &&
            categories?.map((category) => (
              <ProductSection
                key={category.id}
                category={category}
                products={products.filter(
                  (product) => product.category?.id === category.id
                )}
                isLoading={productsLoading}
              />
            ))}
        </div>
      </Layout>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  categories: selectCategoriesData,
  categoriesLoading: selectCategoriesLoading,
  products: selectProductsData,
  productsLoading: selectProductsLoading,
});

const mapDispatchToProps = {
  getAllCategoriesIfNecessary,
  getAllProductsIfNecessary,
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);
