const firebaseConfig = {
	apiKey: "AIzaSyC2sx1T_GfPtcsdUh8HJEB_kPcRhHLXnAE",
	authDomain: "dgamerz.firebaseapp.com",
	databaseURL: "https://dgamerz.firebaseio.com",
	projectId: "dgamerz",
	storageBucket: "dgamerz.appspot.com",
	messagingSenderId: "1070285236681",
	appId: "1:1070285236681:web:9216ec46a4a327e35b6018",
	measurementId: "G-PP3EWJKJCC"
};

export default firebaseConfig;
