import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { createStructuredSelector } from "reselect";
// import sideBannerImg from "../../assets/images/signin/banner.png";
import sideBannerImg from "../../assets/images/signin/login-banner.jpeg";

//style
import "./SignUp.css";

//assets
import Logo from "../../assets/images/logo/logo.png";

//components
import Error from "../../components/Error/Error";
import CustomInput from "../../components/CustomInput/CustomInput";
import CustomButton from "../../components/CustomButton/CustomButton";

// actions
import { signUpUser, updateError } from "../../store/actions/user";

//helpers
import { validateSignUpData } from "./../../utils/validators";
import {
  selectUserData,
  selectUserError,
  selectUserLoading,
} from "../../store/selectors/user";

const Signup = () => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    phone: "",
    country: "",
    email: "",
    password: "",
    passwordConfirmation: "",
  });

  const {
    firstName,
    lastName,
    phone,
    country,
    email,
    password,
    passwordConfirmation,
  } = formData;

  const history = useHistory();
  const dispatch = useDispatch();

  const { userData, userError, userLoading } = useSelector(
    createStructuredSelector({
      userData: selectUserData,
      userError: selectUserError,
      userLoading: selectUserLoading,
    })
  );

  useEffect(() => {
    if (userData) {
      history.push("/");
    }
  }, [userData, history]);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const userData = {
      firstName,
      lastName,
      phoneNumber: phone,
      country,
      email,
      password,
      confirmPassword: passwordConfirmation,
    };

    const { valid, errors } = validateSignUpData(userData);
    if (valid) {
      dispatch(signUpUser(userData));
    } else {
      dispatch(updateError(errors));
    }
  };

  return (
    <div className="signup-page">
      <div className="leftBox">
        <div className="leftBanner b-primary">
          <h2>Unlock a World of Possibilities</h2>
          <p>
            Create your account and step into a world tailored just for you.
            Sign up now and start exploring endless opportunities.
          </p>
          <img src={sideBannerImg} alt="Side banner" />
        </div>
      </div>
      <div className="rightBox">
        <Link to="/">
          <img src={Logo} alt="logo" />
        </Link>
        <h1 className="h2 mb-3">Sign Up</h1>
        <form onSubmit={handleSubmit}>
          {userError.error && <Error error={userError.error} />}
          <div className="row mb-3">
            <div className="col-md-6">
              <CustomInput
                className="form-control form-control-lg"
                placeholder="First Name"
                name="firstName"
                type="text"
                value={firstName}
                onChange={(event) => {
                  const { value } = event.target;

                  if (/^[A-Za-z]*$/.test(value)) {
                    handleChange(event); // Call handleChange only if valid
                  }
                }}
                error={userError.firstName}
              />
            </div>
            <div className="col-md-6">
              <CustomInput
                className="form-control form-control-lg"
                placeholder="Last Name"
                type="text"
                name="lastName"
                value={lastName}
                onChange={(event) => {
                  const { value } = event.target;
                  if (/^[A-Za-z]*$/.test(value)) {
                    handleChange(event);
                  }
                }}
                error={userError.lastName}
              />
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-md-6">
              <CustomInput
                className="form-control form-control-lg"
                placeholder="Phone Number"
                name="phone"
                type="number"
                value={phone}
                onChange={handleChange}
                error={userError.phoneNumber}
              />
            </div>
            <div className="col-md-6">
              <CustomInput
                className="form-control form-control-lg"
                placeholder="Country"
                name="country"
                value={country}
                onChange={handleChange}
                error={userError.country}
              />
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-md-12">
              <CustomInput
                className="form-control form-control-lg"
                placeholder="Email"
                name="email"
                value={email}
                onChange={handleChange}
                error={userError.email}
              />
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-md-6">
              <CustomInput
                className="form-control form-control-lg"
                placeholder="Password"
                type="password"
                name="password"
                value={password}
                onChange={handleChange}
                error={userError.password}
              />
            </div>
            <div className="col-md-6">
              <CustomInput
                className="form-control form-control-lg"
                placeholder="Password Confirmation"
                name="passwordConfirmation"
                type="password"
                value={passwordConfirmation}
                onChange={handleChange}
                error={userError.passwordConfirmation}
              />
            </div>
          </div>
          <CustomButton
            className="button-primary mb-3 w-100"
            isLoading={userLoading}
          >
            Submit
          </CustomButton>
        </form>
        <p className="text-center smallText">
          Have an account?&nbsp;
          <Link to="/login">
            <b>Sign In</b>
          </Link>
        </p>
      </div>
    </div>
  );
};

export default Signup;
