import React from 'react';

function CartListFooter({ total, currency }) {
	return (
		<>
			<div className='cart-total cart-total-subtotal'>
				<div className='cart-total-label'>
					<span className='text'>Total amount:</span>
				</div>
				<div className='cart-total-value'>
					<span className='text'>{total.toFixed(2)} {currency}</span>
				</div>
			</div>
		</>

	);
}

export default CartListFooter;
