import React from "react";
import { Link } from "react-router-dom";
import { createStructuredSelector } from "reselect";

// redux
import { connect } from "react-redux";
import { logoutUser, getUserData } from "../../store/actions/user";
import { selectUserData } from "../../store/selectors/user";
// components
// import { ReactComponent as UserIcon } from '../../assets/icons/user.svg';

//CSS
import "./DropdownUser.css";

const userIcon = (
	<svg
	  xmlns="http://www.w3.org/2000/svg"
	  width="20"
	  height="20"
	  viewBox="0 0 24 24"
	  fill="none"
	  stroke="currentColor"
	  strokeWidth="2"
	  strokeLinecap="round"
	  strokeLinejoin="round"
	  className="me-2"
	>
	  <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
	  <circle cx="12" cy="7" r="4"></circle>
	</svg>
  );

  // SVG for Chevron Down Icon
  const chevronDownIcon = (
	<svg
	  xmlns="http://www.w3.org/2000/svg"
	  width="20"
	  height="20"
	  viewBox="0 0 24 24"
	  fill="none"
	  stroke="currentColor"
	  strokeWidth="2"
	  strokeLinecap="round"
	  strokeLinejoin="round"
	  className="chevron-down"
	>
	  <polyline points="6 9 12 15 18 9"></polyline>
	</svg>
  );



class DropdownUser extends React.Component {
  constructor() {
    super();
    this.state = {
      show: false,
	  showDropdown: false,
    };
    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }
  

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
    if (!this.props.user) this.props.getUserData();
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  setWrapperRef(node) {
    this.wrapperRef = node;
  }

  logoutClick() {
    this.setState({ show: !this.state.show });
	window.location.href = '/'; 
  }


  handleClick = () => {
    this.setState({ showDropdown: !this.state.showDropdown });
  };

  handleClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this.setState({
        show: false,
      });
    }
  }
  render() {
    const { user, logoutUser } = this.props;
    const { showDropdown } = this.state;

    return (
      <div className="dropdown" ref={this.setWrapperRef}>
        {user ? (
          <>
            {/* User icon, name, and chevron-down */}
            <span
              className="user-info"
              onClick={this.handleClick}
              style={{
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
              }}
            >
                 {userIcon}
              <span className="me-2 d-none d-md-block">{user.firstName || user.displayName}</span>
              {chevronDownIcon}
            </span>

            {/* Dropdown for Sign Out */}
            {showDropdown && (
              <div
                className="dropdown-menu"
                style={{
                  position: "absolute",
                  background: "white",
                  boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
                  padding: "10px",
                  marginTop: "5px",
                  borderRadius: "4px",
                }}
              >
                <span>
                 Hello, {user.firstName || user.displayName}
                </span>
                <span className="logout" onClick={() => { logoutUser();	this.logoutClick();this.setState({ showDropdown: false });}}
                  style={{ cursor: "pointer" }}
                >
                  Sign Out
                </span>
              </div>
            )}
          </>
        ) : (
          <span className="">
            <Link
              to="/login"
              className="me-0 text-nowrap"
              style={{ textDecoration: "none" }}
              onMouseOver={(e) =>
                (e.target.style.textDecoration = "underline")
              }
              onMouseOut={(e) => (e.target.style.textDecoration = "none")}
            >
              Sign In
            </Link>
          </span>
        )}
      </div>
    );
  }
}


const mapStateToProps = createStructuredSelector({
  user: selectUserData,
});

function mapDispatchToProps(dispatch) {
  return {
    logoutUser: () => {
      dispatch(logoutUser());
    },
    getUserData: () => {
      dispatch(getUserData());
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(DropdownUser);
