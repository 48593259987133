import { createStore, applyMiddleware, compose } from 'redux';
import rootReducer from './reducers/index';

import logger from 'redux-logger';
import { thunk } from 'redux-thunk'; // Correct import for thunk
import { persistStore } from 'redux-persist';

const middleWares = [thunk]; // Use the named import

if (process.env.NODE_ENV === 'development') {
	middleWares.push(logger);
}

// Setting up Redux DevTools Extension
const composeEnhancers =
	process.env.NODE_ENV === 'development' &&
	window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
		? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
		: compose;

export const store = createStore(
	rootReducer,
	composeEnhancers(applyMiddleware(...middleWares))
);

export const persistor = persistStore(store);

export default { store, persistor };
