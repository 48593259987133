import React from 'react';
import ProductItem from '../ProductItem/ProductItem';

function ProductsList({ products }) {
	return (
		<div className='row row-cols-xxl-5'>
			{products.map((product) => {
				return <ProductItem {...product} key={product.id} />;
			})}
		</div>
	);
}

export default ProductsList;
