import React from 'react';
import BackButton from '../../BackButton/BackButton';
import { Link } from 'react-router-dom';

function CartNavButtons({ goBack }) {
	return (
		<div className='d-flex flex-column mt-4'>
			<Link to='/checkout' className='w-100'>
				<button className='button-primary w-100'>Check Out</button>
			</Link>
			<Link to='/products' className='w-100 mt-3'>
				<button className='button-primary w-100'>Continue Shopping</button>
			</Link>
		</div>
	);
}

export default CartNavButtons;
