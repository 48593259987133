import React from 'react';

import CartNavButtons from './CartNavButtons/CartNavButtons';
import CartListFooter from './CartListFooter/CartListFooter';
import CartListItem from './CartListItem/CartListItem';

function CartList({ goBack, products, removeFromCart, incrementQuantity, decrementQuantity, total }) {
	return (
		<div className='w-100 cart mt-3'>
			<div className='row'>
				<div className='col-md-9'>
					<div className='cart-header'>
						<div className='cart-header-item cart-header-info'>Product</div>
						<div className='cart-header-item cart-header-price'>Price</div>
						<div className='cart-header-item cart-header-quantity'>Quantity</div>
						<div className='cart-header-item cart-header-total'>Total</div>
					</div>
					<div className='cart-list'>
						{products.map((product) => {
							return <CartListItem product={product}
								removeFromCart={removeFromCart}
								incrementQuantity={incrementQuantity}
								decrementQuantity={decrementQuantity}
								key={product.id} />;
						})}
					</div>
				</div>
				<div className='col-md-3 mt-4 mt-md-0'>
					<div className='cart-content-item cart-total'>
						<div class="cart--totals-title">Order Summary</div>
					</div>
					<CartListFooter total={total} currency={products[0].currency} />
					<CartNavButtons goBack={goBack} />
				</div>
			</div>
		</div>
	);
}

export default CartList;
