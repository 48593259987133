import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import { getAllCategoriesIfNecessary } from "../../../store/actions/categories";

//selectors
import { selectNumberOfProductsInCart } from "../../../store/selectors/cart";
import {
  selectNumberOfFavoritesProducts,
  selectUserData,
} from "../../../store/selectors/user";
import { selectCategoriesData } from "../../../store/selectors/categories";

// css
import "./Header.scss";

// assets
import logo from "../../../assets/images/logo/logo.png";
// import logo from "../../../assets/images/logo/logo.png";

import { ReactComponent as ShoppingCart } from "../../../assets/icons/shopping-cart.svg";

import { ReactComponent as Favorite } from "../../../assets/icons/favorite.svg";

//components
import DropdownUser from "../../Dropdown/DropdownUser";
import DropdownSearch from "../../Dropdown/DropdownSearch";

function Header({ numberOfProducts, numberOfFavorites, categories, user }) {
  const [menuVisible, setMenuVisible] = useState(false)
  const [searchVisible, setSearchVisible] = useState(false)

  const toggleMenu = () => {
    setMenuVisible(!menuVisible)
  }
  const toggleSearch = () => {
    setSearchVisible(!searchVisible)
  }

  useEffect(() => {
    if (menuVisible) {
      document.body.classList.add('menu-open');
    } else {
      document.body.classList.remove('menu-open');
    }

    // Optional cleanup to ensure class is removed if component unmounts
    return () => {
      document.body.classList.remove('menu-open');
    };
  }, [menuVisible]);

  return (
    <>
      <header className="border-bottom">
        <div
          className="nav-bar position-relative container container-min-max-width d-flex align-items-center
      "
        >
          <div className="hamburgerIcon" onClick={toggleMenu}>
            <i className="fa-regular fa-bars"></i>
          </div>
          <Link to="/" className="logo-link me-md-0">
            <div className="d-flex align-items-center">
              <img src={logo} alt={`Never Me Always We`} className="logo" />
            </div>
          </Link>
          <div className={`headerAdditional ${searchVisible ? "search-visible" : ""}`}>
            <div className="headerBottomArea">
              <div className="search-wrapper w-100 mx-5">
                <DropdownSearch />
                <span className="closeSearchModal d-md-none" onClick={() => { setSearchVisible(false) }}>
                  <i className="fa-regular fa-times"></i>
                </span>
              </div>
            </div>
          </div>
          <div className="icons-wrapper d-flex">
            <div>
              <div className="d-flex justify-content-end">
                <div className="d-flex align-items-center">
                  <Link
                    to={user ? "/products/?category=favorites" : "/login"}
                    className="d-md-flex d-none position-relative"
                  >
                    <Favorite />
                    {numberOfFavorites !== 0 && (
                      <span className="badge">{numberOfFavorites}</span>
                    )}
                  </Link>

                  <Link
                    to={user ? "/cart" : "/login"}
                    className="d-md-flex d-none position-relative mr-0"
                  >
                    <ShoppingCart />

                    {numberOfProducts >= 1 && (<span className="badge">{numberOfProducts}</span>)}
                  </Link>
                  <span className="">
                    <DropdownUser />
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="separator position-static"></div>
        {menuVisible && (<div className="sidePanelOverlay" onClick={() => { setMenuVisible(false) }}></div>)}

        <div className={`d-flex justify-content-center py-3 categories-list-container ${menuVisible ? 'menu-visible' : ''}`} style={{ background: "#EBEBD3" }}>
          {categories.map((category) => {
            return (
              <Link
                key={category.id}
                to={`/products/?category=${category.id}`}
              >
                <img src={category.imageUrl} />{category.name}
              </Link>
            );
          })}
        </div>
      </header>
      <div className="bottomMenu">
        <span>
          <Link to="/" className="">
            <i className="fa-regular fa-home"></i>
          </Link>
        </span>
        <span onClick={toggleSearch}>
          <i className="fa-regular fa-search"></i>
        </span>
        <span>
          <Link
            to={user ? "/products/?category=favorites" : "/login"}
            className="d-flex position-relative"
          >
            <Favorite />
            {numberOfFavorites !== 0 && (
              <span className="badge">{numberOfFavorites}</span>
            )}
          </Link>
        </span>
        <span>
          <Link
            to={user ? "/cart" : "/login"}
            className="d-flex position-relative mr-0"
          >
            <ShoppingCart />

            {numberOfProducts >= 1 && (<span className="badge">{numberOfProducts}</span>)}
          </Link>
        </span>
      </div>
    </>
  );
}

const mapStateToProps = createStructuredSelector({
  numberOfProducts: selectNumberOfProductsInCart,
  numberOfFavorites: selectNumberOfFavoritesProducts,
  categories: selectCategoriesData,
  user: selectUserData,
});

const mapDispatchToProps = (dispatch) => ({
  getAllCategoriesIfNecessary: (state) => {
    dispatch(getAllCategoriesIfNecessary(state));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
