import React from "react";
import { Link } from "react-router-dom";

function CartListItem({
  product,
  removeFromCart,
  incrementQuantity,
  decrementQuantity,
}) {
  const { imageUrl, title, price, currency, quantity, color, size, id } =
    product;
  return (
    <div className="cart-item" key={product.id}>
      <div className="cart-item-block cart-item-info">
        <div className="cart-item-wrapper">
          <div className="cart-item-block-left">
            <Link to={`/product/${id}`} className='cart-item-image image-portrait'>
              <img
                className="cart__image blur-up lazyloaded"
                src={imageUrl}
                alt=""
              />
            </Link>
          </div>
          <div className="cart-item-block-right">
            <h4 className="cart-item-name">
              <Link to={`/product/${id}`} className='link link-underline'>{title}</Link>
            </h4>
            <div className="cart-item-option">
              <div className="cart-item-variant">Color: {color}</div>
              <div className="cart-item-variant"> Size: {size}</div>
            </div>
            <div className="cart-item-blocks small-screen-visible">
              <div className="cart-item-blocks-left">
                <div className="cart-item-block cart-item-quantity small-screen-visible">
                  <button className="minus btn-quantity" onClick={() => decrementQuantity({ id })} disabled={quantity === 1}></button>
                  <span className="quantity cart-item-qty-input">{quantity}</span>
                  <button className="plus btn-quantity" onClick={() => incrementQuantity({ id })}></button>
                </div>
              </div>
              <div class="cart-item-block cart-item-remove small-screen-visible">
                <a href="javascript:void(0)" className="btn btn--secondary cart__remove" onClick={() => removeFromCart({ id })}>
                  <i className="fa-regular an fa-times"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="cart-item-block cart-item-price">
        <div className="cart-item__price-wrapper">
          <span className="money">{price} {currency}</span>
        </div>
      </div>

      <div className="cart-item-block cart-item-quantity">
        <div className="cart-item-qty">
          <button className="minus btn-quantity" onClick={() => decrementQuantity({ id })} disabled={quantity === 1}></button>
          <span className="quantity cart-item-qty-input">{quantity}</span>
          <button className="plus btn-quantity" onClick={() => incrementQuantity({ id })}></button>
        </div>
      </div>
      <div className="cart-item-block cart-item-total">
        <span className="cart-item-value">
          <span className="money">
            {(price * quantity).toFixed(2)} {currency}
          </span>
        </span>
      </div>
      <div className="cart-item-block cart-item-remove">
        <a href="javascript:void(0)" className="cart-remove" onClick={() => removeFromCart({ id })}>
          <i className="fa-regular an fa-times"></i>
        </a>
      </div>
    </div>
  );
}

export default CartListItem;
