import React from 'react';
import { Link } from 'react-router-dom';

function ShowAllProductsButton() {
	return (
		<div>
			 
			<div className='d-flex justify-content-center'>
				<Link to='/products'>
					<button className='button-primary'>View all products</button>
				</Link>
			</div>
			 
		</div>
	);
}

export default ShowAllProductsButton;
